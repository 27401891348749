import React from 'react';
import * as PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { links } from 'sf-modules';
import Card from '@/yema-designkit/webkit/molecules/Card';

const CMS_CardImageText = ({ content }) => {
    const router = useRouter();
    const handleClick = () => {
        if (content.button && content.button.link)
            links.handleRedirect(content.button.link, router);
    };

    return (
        <div className='h-full w-full'>
            <Card cardInfo={content} handleBtnClick={handleClick} />
        </div>
    );
};

CMS_CardImageText.defaultProps = {};

CMS_CardImageText.propTypes = {
    content: PropTypes.object.isRequired,
};

export default React.memo(CMS_CardImageText);
