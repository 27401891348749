import React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import useMedia from 'use-media';
import { ChevronRightIcon } from 'icons/solid';
import Image from '../../atoms/Image';
import Link from '../../atoms/Link';
import Button from '../../atoms/Button';
import styles from './Card.module.scss';

const CardContent = ({ data, handleBtnClick }) => {
    const isMobile = useMedia({ maxWidth: 639 });

    const { image, layout, title, content, button, alignment, background_color, text_color } = data;

    const withButton = button.link && button.text;
    const noBackground = background_color === 'no'; //No tiene background

    //@TODO Need to maintain old keys (CARD_*) to ensure backward compatbility with Wagtail pages
    // created before 2020-10-21 (and until all pages are updated)
    //LAYOUTS:
    const imageTop = layout === 'image_top' || layout === 'CARD_IMAGE_TOP';
    const imageSideRight =
        (layout === 'image_side' && alignment === 'right') || layout === 'CARD_IMAGE_RIGHT';
    const imageSideLeft =
        (layout === 'image_side' && alignment === 'left') || layout === 'CARD_IMAGE_LEFT';
    const imageSide = imageSideRight || imageSideLeft;
    const imageDescription =
        layout === 'description_form' || layout === 'CARD_IMAGE_FORM_BOTTOM_LEFT';
    const imageBlog = layout === 'blog';

    const left = alignment === 'left';
    const center = alignment === 'center';
    const right = alignment === 'right';

    /** *** CONTAINER CLASSNAMES */
    const cardContainer = cx(
        'flex flex-col duration-300 ease-out rounded-md relative h-full',
        styles.cardWrapper,
        {
            'sm:flex-row': !imageTop,
            'sm:flex-row-reverse group': imageSideRight,
            'sm:flex-row group': imageSideLeft,
            'cursor-pointer hover:shadow-down':
                (withButton && imageTop) || (withButton && imageDescription) || imageBlog,
        }
    );

    /** *** IMAGE CLASSNAMES */
    const classnamesImageDescription =
        'w-auto sm:w-full rounded-tl-md rounded-tr-md rounded-none sm:rounded-md';
    const classnamesImageBlog =
        'w-full rounded-none sm:rounded-tr-md sm:rounded-br-md sm:rounded-l-none';
    const classnamesImageTop = 'w-full rounded-tl-md rounded-tr-md rounded-b-none';
    const classnamesImageSide = cx('rounded-t-md rounded-b-none', {
        'cursor-pointer group-hover:shadow-down': withButton,
        'sm:rounded-t-none sm:rounded-l-md sm:rounded-r-none': imageSideLeft,
        'sm:rounded-t-none sm:rounded-l-none sm:rounded-r-md': imageSideRight,
    });
    const imageClass = cx('bg-center bg-cover bg-no-repeat duration-300', {
        [classnamesImageDescription]: imageDescription,
        [classnamesImageBlog]: imageBlog,
        [classnamesImageTop]: imageTop,
        [classnamesImageSide]: imageSide,
    });

    /** *** CARD CLASSNAMES */
    const classnamesCardImageBlog = cx(
        'absolute text-left rounded-tr-md rounded-tl-md rounded-b-none bottom-0 pt-4 sm:pt-6 mr-auto sm:mr-4',
        'left-1/2 sm:left-auto right-auto sm:right-0 transform -translate-x-1/2 sm:-translate-x-0'
    );
    const classnamesCardImageTop = cx(
        'h-full rounded-bl-md rounded-br-md rounded-t-none justify-between flex flex-col',
        {
            'text-center': center,
            'text-left': left,
            'text-right': right,
        }
    );
    const classnamesCardImageSide =
        'text-left justify-center items-start flex flex-col rounded-none sm:rounded-l-md';
    const classnamesCardImageDescription = cx('sm:absolute transform-none sm:transform', {
        'text-left': left,
        'text-center': center,
        'text-right': right,

        // IMAGE DESCRIPTION WITHOUT BACKGROUND COLOR
        'bottom-0 left-0': noBackground && left,
        'bottom-0 right-0': noBackground && right,
        'top-1/2 left-1/2 sm:-translate-x-1/2 sm:-translate-y-1/2': noBackground && center,

        // IMAGE DESCRIPTION WITH BACKGROUND COLOR
        'sm:top-1/2 sm:-translate-y-1/2 sm:rounded-r-md sm:rounded-l-none': !noBackground && left,
        'sm:top-1/2 sm:right-0 sm:-translate-y-1/2 sm:rounded-l-md sm:rounded-r-none':
            !noBackground && right,
        'sm:bottom-0 sm:left-1/2 sm:-translate-x-1/2 sm:rounded-t-md sm:rounded-b-none':
            !noBackground && center,
    });
    const cardClass = cx('p-2 sm:p-4', styles.card, {
        [classnamesCardImageBlog]: imageBlog,
        [classnamesCardImageTop]: imageTop,
        [classnamesCardImageSide]: imageSide,
        [classnamesCardImageDescription]: imageDescription,
    });

    const setContainerHeight = {
        minHeight: imageSide
            ? '209px'
            : imageDescription
            ? '289px'
            : imageBlog && !isMobile
            ? '310px'
            : imageBlog && isMobile
            ? '275px'
            : '',
        height: imageTop && '100%',
    };

    return (
        <div className={cardContainer} style={setContainerHeight}>
            {image && (
                <div
                    className={cx('relative w-full', {
                        'min-w-1/2': imageSide,
                        'pb-36': imageTop || (!imageBlog && isMobile),
                        'min-h-88': imageSide && !isMobile,
                        'min-h-68': imageBlog && isMobile,
                    })}
                >
                    <Image
                        className={imageClass}
                        alt={title}
                        src={image}
                        layout='fill'
                        objectFit='cover'
                        objectPosition='center'
                        quality={70}
                    />
                </div>
            )}

            <div
                className={cx(cardClass, {
                    'min-w-auto sm:min-w-2/3 sm:max-w-10/12 lg:min-w-3/4 xl:min-w-2/3':
                        imageDescription,
                    'w-5/6 sm:w-3/4': imageBlog,
                })}
                style={{
                    minWidth: imageSide ? '47%' : '',
                    minHeight: imageBlog
                        ? '150px'
                        : imageTop && !withButton && !isMobile
                        ? '164px'
                        : '',
                    backgroundColor:
                        background_color === 'yes' && !imageBlog
                            ? '#FCFCFC'
                            : background_color === 'no'
                            ? 'transparent'
                            : imageBlog
                            ? 'rgba(252,252,252,.95)'
                            : background_color,
                    color: text_color ? text_color : '#100C17',
                }}
            >
                {imageBlog && (
                    <div
                        className={cx(
                            'mb-2 flex items-baseline justify-between sm:mb-4',
                            styles.category
                        )}
                    >
                        <p className='mt-1 mb-0 font-body text-small font-body-regular'>Leer más</p>
                        <ChevronRightIcon className='ml-2 w-5' />
                    </div>
                )}

                {title && (
                    <h3
                        className={cx('mb-2 font-heading text-h3 font-heading-medium', {
                            'text-left': imageSide,
                        })}
                    >
                        {title}
                    </h3>
                )}

                {!imageBlog && content && (
                    <div className='mb-2' dangerouslySetInnerHTML={{ __html: content }} />
                )}

                {withButton && !imageBlog && (
                    <Button
                        className={cx('mt-0 w-full px-3 leading-4', {
                            'group-hover:shadow-down': withButton && imageSide,
                            'mx-auto block': alignment === 'center',
                            'self-start': alignment === 'left',
                            'self-end': alignment === 'right',
                            'w-auto': imageDescription,
                        })}
                        style={{ maxWidth: '190px' }}
                        size='full'
                        onClick={handleBtnClick(button.link)}
                    >
                        {button.text}
                    </Button>
                )}
            </div>
        </div>
    );
};

const Card = ({ cardInfo, handleBtnClick }) => {
    const cardButton = cardInfo.button.link;

    if (cardButton)
        return (
            <Link underline={false} href={cardButton} className='h-full'>
                <CardContent data={cardInfo} handleBtnClick={handleBtnClick} />
            </Link>
        );

    return <CardContent data={cardInfo} handleBtnClick={handleBtnClick} />;
};

Card.propTypes = {
    cardInfo: PropTypes.object,
    handleBtnClick: PropTypes.func.isRequired,
};

export default Card;
